import { LocationContext } from "@reach/router";
import queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import { actions as userActions } from "src/store/User";

interface ConnectDispatchProps {
  login(email: string, password: string, token?: string): Promise<boolean>;
}

interface Props extends ConnectDispatchProps, LocationContext {}

class OauthSuccess extends React.Component<Props> {
  public async componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const isToken = typeof query.token === "string";
    const isEmail = typeof query.email === "string";

    if (isToken && isEmail) {
      // @ts-ignore
      await this.props.login(query.email, "token", query.token).catch((e) => {
        console.error(e);
      });
    }
    await this.props.navigate("/");
  }

  public render() {
    return <div />;
  }
}

const mapActionsToProps = {
  login: userActions.login,
};

export default connect<null, ConnectDispatchProps>(
  null,
  // @ts-ignore
  mapActionsToProps
)(OauthSuccess);
